import React, { memo, useContext } from 'react';
import { AiOutlineUserAdd } from 'react-icons/ai';
import Button from '@/components/shared/Button';
import ModalContext from '@/contexts/ModalContext';

const ManageVisibleToAdminToggleButton = ({ users }) => {
  const { emitter, events } = useContext(ModalContext);

  const handleClick = () => {
    emitter.emit(
      events.UPDATE_USER_PERMISSION_OF_VISIBLE_TO_ADMIN_MENU_MODAL,
      users,
    );
  };

  return (
    <Button
      outline
      icon={AiOutlineUserAdd}
      onClick={handleClick}
      style={{ margin: '0 auto' }}
      className="ml-auto mr-auto"
    >
      Manage VisibleToAdmin Toggle Button
    </Button>
  );
};

export default memo(ManageVisibleToAdminToggleButton);
