import { Redirect, Router } from '@reach/router';
import React, { memo } from 'react';
import Builder from './app/builder';
import Dashboard from './app/dashboard';
import Settings from './app/settings';
import Adminboard from './app/adminboard';
import SuperAdminboard from './app/superAdminboard';
import NotFound from './404';
import PrivateRoute from '../components/router/PrivateRoute';
import Wrapper from '../components/shared/Wrapper';

const App = () => {
  const privateRoutes = [
    { path: '/app/dashboard', component: Dashboard, role: 'user' },
    { path: '/app/settings', component: Settings, role: 'user' },
    { path: '/app/adminboard', component: Adminboard, role: 'admin' },
    {
      path: '/app/superAdminboard',
      component: SuperAdminboard,
      role: 'superAdmin',
    },
    { path: '/app/builder/:id', component: Builder, role: 'user' },
  ];
  return (
    <Wrapper>
      <Router>
        <Redirect noThrow from="/app" to="/app/dashboard" exact />
        {privateRoutes.map(({ path, component, role }) => (
          <PrivateRoute
            key={path}
            path={path}
            component={component}
            role={role}
          />
        ))}
        <NotFound default />
      </Router>
    </Wrapper>
  );
};
export default memo(App);
