import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import firebase from 'gatsby-plugin-firebase';
import ManageVisibleToAdminToggleButton from '@/components/adminboard/ManageVisibleToAdminToggleButton';
import Footer from '@/components/Footer';
import LoadingScreen from '../../components/router/LoadingScreen';
import ResumePreview from '../../components/dashboard/ResumePreview';
import TopNavbar from '../../components/dashboard/TopNavbar';

const Adminboard = ({ user }) => {
  const { t } = useTranslation();
  const [resumes, setResumes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const resumesRef = 'resumes';
    const usersRef = 'users';
    const socketRef = '/.info/connected';

    firebase
      .database()
      .ref(socketRef)
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          setLoading(false);
          firebase.database().ref(socketRef).off();
        }
      });

    firebase
      .database()
      .ref(resumesRef)
      .orderByChild('user')
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          const resumesArr = [];
          const data = snapshot.val();
          Object.keys(data).forEach((key) => {
            if (data[key].visibleToAdmin?.show) {
              resumesArr.push(data[key]);
            }
          });
          setResumes(resumesArr);
        }
      });

    firebase
      .database()
      .ref(resumesRef)
      .orderByChild('user')
      .on('child_removed', (snapshot) => {
        if (snapshot.val()) {
          setResumes(resumes.filter((x) => x.id === snapshot.val().id));
        }
      });

    firebase
      .database()
      .ref(usersRef)
      .orderByChild('email')
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          const usersArr = [];
          const data = snapshot.val();
          Object.keys(data).forEach((key) => {
            if (data[key].email) {
              usersArr.push(data[key]);
            }
          });
          setUsers(usersArr);
        }
      });

    return () => {
      firebase.database().ref(resumesRef).off();
      firebase.database().ref(usersRef).off();
    };
  }, [user]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <div>
        <Helmet>
          <title>
            {t('adminboard.title')} | {t('shared.appName')}
          </title>
          <link
            rel="canonical"
            href="https://cv.orientalent.se/app/adminboard"
          />
        </Helmet>

        <TopNavbar />

        <div className="container mt-12 px-12 xl:px-0 relative">
          <h1 className="text-3xl font-bold absolute left-0 top-0">
            All visible profiles
          </h1>
          <ManageVisibleToAdminToggleButton users={users} />
        </div>

        <div className="container mt-12 px-12 xl:px-0">
          <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-6 gap-8">
            {resumes.length > 0 ? (
              resumes.map((x) => (
                <ResumePreview
                  key={x.id}
                  resume={x}
                  showDeleteMenu={false}
                  showDuplicateMenu={false}
                  styleType="admin"
                />
              ))
            ) : (
              <h1 className="font-bold">No resumes</h1>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Adminboard;
